// Generated by Framer (915daba)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import NavMenu from "./a7w3v2CCa";
const NavMenuFonts = getFonts(NavMenu);

const serializationHash = "framer-QMu2U"

const variantClassNames = {N3OBxYVPi: "framer-v-x95xyp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({border, click, height, id, text, title, width, ...props}) => { return {...props, G3E2h9tMe: border ?? props.G3E2h9tMe ?? {borderColor: "rgba(0, 0, 0, 0)", borderStyle: "solid", borderWidth: 1}, gy_pGj8Ff: title ?? props.gy_pGj8Ff ?? "Products", S3d3vAfCg: click ?? props.S3d3vAfCg, wyeazkY3L: text ?? props.wyeazkY3L ?? "rgb(0, 0, 0)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;title?: string;border?: Record<string, any>;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, wyeazkY3L, gy_pGj8Ff, G3E2h9tMe, S3d3vAfCg, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "N3OBxYVPi", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap3d0qxh = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (S3d3vAfCg) {const res = await S3d3vAfCg(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-x95xyp", className, classNames)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"N3OBxYVPi"} onTap={onTap3d0qxh} ref={ref ?? ref1} style={{"--border-bottom-width": (G3E2h9tMe.borderBottomWidth ?? G3E2h9tMe.borderWidth) + "px", "--border-color": G3E2h9tMe.borderColor, "--border-left-width": (G3E2h9tMe.borderLeftWidth ?? G3E2h9tMe.borderWidth) + "px", "--border-right-width": (G3E2h9tMe.borderRightWidth ?? G3E2h9tMe.borderWidth) + "px", "--border-style": G3E2h9tMe.borderStyle, "--border-top-width": (G3E2h9tMe.borderTopWidth ?? G3E2h9tMe.borderWidth) + "px", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}}><ComponentViewportProvider height={24} y={((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 24) - 0) - 24) / 2)))}><SmartComponentScopedContainer className={"framer-1x7v7kr-container"} layoutDependency={layoutDependency} layoutId={"d7cFF4JU3-container"} nodeId={"d7cFF4JU3"} rendersWithMotion scopeId={"K_5IHclTD"}><NavMenu Dl6AiX37G={wyeazkY3L} height={"100%"} id={"d7cFF4JU3"} kcKdHX2wm={gy_pGj8Ff} layoutId={"d7cFF4JU3"} variant={"AY2iJjz0z"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QMu2U.framer-p9wgy6, .framer-QMu2U .framer-p9wgy6 { display: block; }", ".framer-QMu2U.framer-x95xyp { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 8px 0px 8px; position: relative; width: min-content; }", ".framer-QMu2U .framer-1x7v7kr-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QMu2U.framer-x95xyp { gap: 0px; } .framer-QMu2U.framer-x95xyp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QMu2U.framer-x95xyp > :first-child { margin-left: 0px; } .framer-QMu2U.framer-x95xyp > :last-child { margin-right: 0px; } }", ".framer-QMu2U[data-border=\"true\"]::after, .framer-QMu2U [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 80
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"wyeazkY3L":"text","gy_pGj8Ff":"title","G3E2h9tMe":"border","S3d3vAfCg":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerK_5IHclTD: React.ComponentType<Props> = withCSS(Component, css, "framer-QMu2U") as typeof Component;
export default FramerK_5IHclTD;

FramerK_5IHclTD.displayName = "Big Menu";

FramerK_5IHclTD.defaultProps = {height: 24, width: 80};

addPropertyControls(FramerK_5IHclTD, {wyeazkY3L: {defaultValue: "rgb(0, 0, 0)", title: "Text", type: ControlType.Color}, gy_pGj8Ff: {defaultValue: "Products", displayTextArea: false, title: "Title", type: ControlType.String}, G3E2h9tMe: {defaultValue: {borderColor: "rgba(0, 0, 0, 0)", borderStyle: "solid", borderWidth: 1}, title: "Border", type: ControlType.Border}, S3d3vAfCg: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerK_5IHclTD, [{explicitInter: true, fonts: []}, ...NavMenuFonts], {supportsExplicitInterCodegen: true})